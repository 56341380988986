import {
  FieldInputProps,
  FieldMeta,
  FormProvider,
  Formulier,
  Primitives,
  useFormField,
  useFormInstance,
  useFormSelector,
  Values,
} from "@formulier/react";
import { cn } from "@nephroflow/design-system/styling/utils";
import { Slot } from "@radix-ui/react-slot";
import { Navigation, Form as RemixForm } from "@remix-run/react";
import * as React from "react";
import { z } from "zod";

import { defineMessages, FormattedMessage, isMessageDescriptor, MessageDescriptor } from "~/intl";

import { useValidateCallback, Validation } from "./form-validation";
import { ErrorIcon, WarningIcon } from "./icon";
import { InlineAlert, InlineAlertContent } from "./inline-alert";

export type FormErrorObject = z.infer<typeof ErrorObjectSchema>;

const ErrorObjectSchema = z.object({
  source: z.union([z.string().min(1), z.literal("")]),
  detail: z.string(),
});
const ActionDataSchema = z.object({ errors: z.array(ErrorObjectSchema) });

export interface FormSubmitContext<V extends Values = Record<string, unknown>> {
  values: V;
  setFieldErrors: (fieldErrors: Record<string, string | null>) => void;
  setGlobalErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

interface FormMessageProps {
  type: "error" | "warning" | "info";
  detailsList?: string[];
  children: MessageDescriptor | React.ReactNode | string;
  showErrorCountMessage: boolean;
}

export function FormMessage({ detailsList, children, showErrorCountMessage }: FormMessageProps) {
  return (
    <InlineAlert
      type="error"
      className={cn({
        "sticky left-0 right-0 top-0 -translate-y-5 rounded-none": showErrorCountMessage,
      })}
    >
      <InlineAlertContent>
        {isMessageDescriptor(children) ? <FormattedMessage {...children} /> : children}
        {detailsList ? (
          <ul>
            {detailsList.map((line, index) => (
              <li key={`${line}_${index}`}>{line}</li>
            ))}
          </ul>
        ) : null}
      </InlineAlertContent>
    </InlineAlert>
  );
}

function Form<V extends Values, P extends Primitives>({
  children,
  form,
  onSubmit,
  navigation,
  actionData,
  showErrorCountMessage = true,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof RemixForm>, "form" | "onSubmit"> & {
  form: Formulier<V, P>;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>, context: FormSubmitContext<V>) => void;
  navigation?: Navigation;
  showErrorCountMessage?: boolean;
  actionData?: any;
}) {
  const [globalErrors, setGlobalErrors] = React.useState<string[]>([]);

  const fieldErrors = useFormSelector(form, (state) => state.errors);
  const submitCount = useFormSelector(form, (state) => state.submitCount);

  const globalErrorCount = globalErrors.length;
  const fieldErrorsCount = Object.values(fieldErrors).filter((err) => err !== null).length;
  const totalErrorCount = globalErrorCount + fieldErrorsCount;
  const showError = submitCount > 0 && (showErrorCountMessage ? totalErrorCount > 0 : globalErrorCount > 0);

  useSyncFormDataErrors(form, navigation, actionData, setGlobalErrors);

  return (
    <RemixForm
      {...props}
      className={cn(props.className, { "-mt-6": totalErrorCount > 0 })}
      onSubmit={(event) => {
        const isValid = form.validateFields();
        if (!isValid) {
          event.preventDefault();
        } else {
          onSubmit?.(event, {
            values: form.store.getState().values as V,
            setFieldErrors: form.setFieldErrors,
            setGlobalErrors,
          });
        }
        form.incrementSubmitCount();
      }}
    >
      {showError ? (
        <FormMessage type="error" detailsList={globalErrors} showErrorCountMessage={showErrorCountMessage}>
          {showErrorCountMessage ? (
            <FormattedMessage {...t.formContainsErrors} values={{ amount: totalErrorCount }} />
          ) : null}
        </FormMessage>
      ) : null}
      <FormProvider form={form}>{children}</FormProvider>
    </RemixForm>
  );
}

function useSyncFormDataErrors<V extends Values, P extends Primitives>(
  form: Formulier<V, P>,
  navigation: Navigation | undefined,
  actionData: unknown,
  setGlobalErrors: React.Dispatch<React.SetStateAction<string[]>>,
) {
  const actionDataRef = React.useRef(actionData);
  actionDataRef.current = actionData;

  const lastStateRef = React.useRef(navigation?.state || "idle");
  const currentState = navigation?.state || "idle";

  React.useEffect(() => {
    const startedSubmission = lastStateRef.current !== "submitting" && currentState === "submitting";

    const finishedSubmission = lastStateRef.current === "submitting" && currentState !== "submitting";

    if (startedSubmission) {
      setGlobalErrors([]);
    }

    if (finishedSubmission) {
      try {
        const { errors } = ActionDataSchema.parse(actionDataRef.current);
        setGlobalErrors(errors.filter((error) => error.source === "").map((error) => error.detail));

        const fieldErrors = Object.fromEntries(errors.map((error) => [error.source, error.detail]));
        form.setFieldErrors(fieldErrors);
      } catch (error) {
        console.log(error);
      }
    }

    lastStateRef.current = currentState;
  }, [form, currentState, setGlobalErrors]);
}

const FieldContext = React.createContext(
  {} as {
    field: FieldInputProps<Values, string>;
    meta: FieldMeta;
    required: boolean;
    inputId: string;
    errorId: string;
    warningId: string;
    helperId: string;
  },
);

function FieldItem({
  className,
  children,
  name,
  validation,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  name: string;
  validation?: Validation;
}) {
  const validate = useValidateCallback(validation);
  const form = useFormInstance();
  const [field, meta] = useFormField(form, { name, validate });

  const required =
    !!validation?.required && (Array.isArray(validation.required) ? validation.required[0] : validation.required);

  return (
    <FieldContext.Provider
      value={{
        field,
        meta,
        required,
        inputId: `${field.id}-input`,
        errorId: `${field.id}-error`,
        warningId: `${field.id}-warning`,
        helperId: `${field.id}-helper`,
      }}
    >
      <div className={cn("mb-4 flex flex-col", className)} {...props}>
        {children}
      </div>
    </FieldContext.Provider>
  );
}

function FieldLabel({ children }: { children: React.ReactNode }) {
  const { required, inputId } = React.useContext(FieldContext);
  return (
    <label className={cn("mb-1 text-secondary")} htmlFor={inputId}>
      {children}
      {!required ? <span className="text-sm before:content-['_-_']">Optional</span> : null}
    </label>
  );
}

function FieldInput({ children }: { children: React.ReactNode }) {
  const { field, meta, inputId, errorId, warningId, helperId } = React.useContext(FieldContext);
  const Comp = Slot as React.ForwardRefExoticComponent<
    React.InputHTMLAttributes<HTMLInputElement> &
      React.RefAttributes<HTMLInputElement> & {
        children?: React.ReactNode;
      }
  >;
  return (
    <>
      <Comp
        id={inputId}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        aria-describedby={`${helperId} ${errorId} ${warningId}`}
        aria-invalid={!!meta.error}
        className="w-full"
      >
        {children}
      </Comp>
      <FieldError />
    </>
  );
}

function FieldError() {
  const { meta, errorId } = React.useContext(FieldContext);
  if (!meta.error) return null;
  return (
    <p role="alert" id={errorId} className={cn("-z-10 -mt-2 flex gap-2 rounded-b bg-red-30 pb-2 pl-4 pr-4 pt-4")}>
      <ErrorIcon className="my-1 h-4 w-4 shrink-0" />
      {meta.error}
    </p>
  );
}

function FieldWarning({ message: getMessage }: { message: (value: unknown) => string | null }) {
  const { field, meta, warningId } = React.useContext(FieldContext);
  if (meta.error) return null;
  const message = getMessage(field.value);
  if (!message) return null;
  return (
    <p role="alert" id={warningId} className={cn("-z-10 -mt-2 flex gap-2 rounded-b bg-yellow-30 pb-2 pl-4 pr-4 pt-4")}>
      <WarningIcon className="my-1 h-4 w-4 shrink-0" />
      {message}
    </p>
  );
}

function FieldHelper({ className, children, ...props }: React.HTMLAttributes<HTMLParagraphElement>) {
  const { helperId } = React.useContext(FieldContext);
  return (
    <p id={helperId} className={cn("pt-1 text-gray-70", className)} {...props}>
      {children}
    </p>
  );
}

export { FieldHelper, FieldInput, FieldItem, FieldLabel, FieldWarning, Form };

const t = defineMessages({
  formContainsErrors: {
    id: "form_form_contains_errors",
    defaultMessage: "Your form contains {amount, plural, one {# error} other {# errors}}.",
  },
});
