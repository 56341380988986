import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";

export const disabledInputClassName = cn(
  "border-gray-30 bg-gray-00 placeholder:text-gray-30 hover:border-gray-30 hover:bg-gray-00",
);

export const inputWithAffixClassName = cn("flex w-full flex-1 bg-transparent outline-none");

export type InputVariantProps = VariantProps<typeof inputVariants>;
export const inputVariants = cva(
  "w-[288px] rounded border border-blue-60 bg-blue-00 px-4 py-3 transition-colors placeholder:text-gray-50",
  {
    variants: {
      size: {
        compact: "py-2",
        default: "py-3",
        large: "py-4",
      },
      disabled: {
        true: "bg-gray-00 text-gray-30 outline-gray-30 placeholder:text-gray-30",
        false: [
          // hover
          "[&:not(:focus-within):hover]:bg-blue-10 [&:not(:focus-within):hover]:outline-blue-50",
          // focus
          "focus-within:outline focus-within:outline-1 focus-within:outline-blue-100",
        ],
      },
    },
    defaultVariants: {
      size: "default",
      disabled: false,
    },
  },
);

export const focusOutlineClassName =
  "focus-visible:outline focus-visible:outline-1 focus-visible:outline-black focus-visible:outline-offset-2";
