import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";
import { Slot, Slottable } from "@radix-ui/react-slot";
import * as React from "react";

import { RequireSome } from "~/shared/utils";

import { focusOutlineClassName } from "./inputs/constants";

const buttonVariants = cva(
  [
    "inline-flex justify-center rounded leading-3 transition-colors",
    focusOutlineClassName,
    "disabled:pointer-events-none",
  ],
  {
    variants: {
      type: {
        textOnly: "",
        iconOnly: "",
        iconAndText: "[&>.button-icon]:mr-2",
      },
      importance: {
        primary: "",
        secondary: "",
        tertiary: "",
      },
      impact: {
        neutral: "",
        affirmative: "",
        negative: "",
        dangerous: "",
        destructive: "",
      },
      size: {
        compact: "",
        default: "",
        large: "",
      },
    },

    // NOTE: Use `[&:not(disabled)]` instead of `enabled` so if we use `asChild` and render a `Link`,
    // the styling will work as expected. `enabled` is valid for `button` elements but not for `a` elements.

    compoundVariants: [
      {
        importance: ["primary", "secondary"],
        class: "disabled:bg-gray-20 disabled:text-gray-50",
      },
      { importance: "tertiary", class: "disabled:text-gray-20" },

      // Button margins for type `textOnly`
      { type: "textOnly", size: "compact", class: "px-3 py-1" },
      { type: "textOnly", size: "default", class: "px-6 py-2" },
      { type: "textOnly", size: "large", class: "px-12 py-4" },

      // Button margins for type `iconOnly`
      { type: "iconOnly", size: "compact", class: "p-1.5" },
      { type: "iconOnly", size: "default", class: "p-2.5" },
      { type: "iconOnly", size: "large", class: "p-[18px]" },

      // Button margins for type `iconAndText`
      { type: "iconAndText", size: "compact", class: "gap-2 py-1 pl-1 pr-3" },
      { type: "iconAndText", size: "default", class: "gap-2 py-2 pl-4 pr-6" },
      { type: "iconAndText", size: "large", class: "gap-2 py-4 pl-10 pr-12" },

      // Impact neutral
      {
        impact: "neutral",
        importance: "primary",
        class: [
          "bg-blue-100 text-white", // default
          "[&:not(disabled)]:hover:bg-blue-110", // hover
          "[&:not(disabled)]:active:bg-blue-120", // active
        ],
      },
      {
        impact: "neutral",
        importance: "secondary",
        class: [
          "bg-blue-30 text-gray-100", // default
          "[&:not(disabled)]:hover:bg-blue-40", // hover
          "[&:not(disabled)]:active:bg-blue-50", // active
        ],
      },
      {
        impact: "neutral",
        importance: "tertiary",
        class: [
          "text-blue-100", // default
          "[&:not(disabled)]:hover:bg-blue-20 [&:not(disabled)]:hover:text-gray-100", // hover
          "[&:not(disabled)]:active:bg-blue-30 [&:not(disabled)]:active:text-gray-100", // active
        ],
      },

      // Impact affirmative
      {
        impact: "affirmative",
        importance: "primary",
        class: [
          "bg-green-100 text-gray-100", // default
          "[&:not(disabled)]:hover:bg-green-110", // hover
          "[&:not(disabled)]:active:bg-green-120", // active
        ],
      },
      {
        impact: "affirmative",
        importance: "secondary",
        class: [
          "bg-green-30 text-gray-100", // default
          "[&:not(disabled)]:hover:bg-green-40", // hover
          "[&:not(disabled)]:active:bg-green-50", // active
        ],
      },
      {
        impact: "affirmative",
        importance: "tertiary",
        class: [
          "text-green-100", // default
          "[&:not(disabled)]:hover:bg-green-20 [&:not(disabled)]:hover:text-gray-100", // hover
          "[&:not(disabled)]:active:bg-green-30 [&:not(disabled)]:active:text-gray-100", // active
        ],
      },

      // Impact negative, destructive
      {
        impact: ["negative", "destructive"],
        importance: "primary",
        class: [
          "bg-red-100 text-white", // default
          "[&:not(disabled)]:hover:bg-red-110", // hover
          "[&:not(disabled)]:active:bg-red-120", // active
        ],
      },
      {
        impact: ["negative", "destructive"],
        importance: "secondary",
        class: [
          "bg-red-30 text-gray-100", // default
          "[&:not(disabled)]:hover:bg-red-40", // hover
          "[&:not(disabled)]:active:bg-red-50", // active
        ],
      },
      {
        impact: ["negative", "destructive"],
        importance: "tertiary",
        class: [
          "text-red-100", // default
          "[&:not(disabled)]:hover:bg-red-20 [&:not(disabled)]:hover:text-gray-100", // hover
          "[&:not(disabled)]:active:bg-red-30 [&:not(disabled)]:active:text-gray-100", // active
        ],
      },

      // Impact dangerous
      {
        impact: "dangerous",
        importance: "primary",
        class: [
          "bg-yellow-100 text-white", // default
          "[&:not(disabled)]:hover:bg-yellow-110", // hover
          "[&:not(disabled)]:active:bg-yellow-120", // active
        ],
      },
      {
        impact: "dangerous",
        importance: "secondary",
        class: [
          "bg-yellow-30 text-gray-100", // default
          "[&:not(disabled)]:hover:bg-yellow-40", // hover
          "[&:not(disabled)]:active:bg-yellow-50", // active
        ],
      },
      {
        impact: "dangerous",
        importance: "tertiary",
        class: [
          "text-yellow-100", // default
          "[&:not(disabled)]:hover:bg-yellow-20 [&:not(disabled)]:hover:text-gray-100", // hover
          "[&:not(disabled)]:active:bg-yellow-30 [&:not(disabled)]:active:text-gray-100", // active
        ],
      },
    ],

    defaultVariants: {
      size: "default",
    },
  },
);

const ButtonBase = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon?: React.ReactNode;
    asChild?: boolean;
  }
>(({ icon, asChild = false, className, children, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";
  return (
    <Comp className={className} ref={ref} {...props}>
      {icon || null}
      <Slottable>{children}</Slottable>
    </Comp>
  );
});
ButtonBase.displayName = "ButtonBase";

const Button = React.forwardRef<
  HTMLButtonElement,
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> &
    RequireSome<Omit<VariantProps<typeof buttonVariants>, "type">, "importance" | "impact"> & {
      icon?: React.ReactNode;
      asChild?: boolean;
      children: React.ReactNode;
    }
>(({ className, importance, impact, size, ...props }, ref) => {
  return (
    <ButtonBase
      className={cn(
        buttonVariants({
          importance,
          impact,
          size,
          type: props.icon ? "iconAndText" : "textOnly",
        }),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Button.displayName = "Button";

const IconButton = React.forwardRef<
  HTMLButtonElement,
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> &
    RequireSome<Omit<VariantProps<typeof buttonVariants>, "type">, "importance" | "impact"> & {
      title: string;
      icon: React.ReactNode;
    }
>(({ className, importance, impact, size, ...props }, ref) => {
  return (
    <ButtonBase
      className={cn(
        buttonVariants({
          importance,
          impact,
          size,
          type: "iconOnly",
        }),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
IconButton.displayName = "IconButton";

export { Button, buttonVariants, IconButton };
