import { MessageDescriptor, useIntl } from "react-intl";

export function isMessageDescriptor(arg: unknown): arg is MessageDescriptor {
  return typeof arg === "object" && arg !== null && "id" in arg && "defaultMessage" in arg;
}

export function useTranslatedResource<Translations extends Record<string, MessageDescriptor>>(
  translations: Translations,
) {
  const { formatMessage } = useIntl();

  function formatValue(key: keyof Translations): string;
  function formatValue(key: keyof Translations | null | undefined): string | null;
  function formatValue(key: keyof Translations | null | undefined) {
    if (!key) return null;
    if (!translations[key]) return key;

    return formatMessage(translations[key]);
  }

  const options = Object.entries(translations).map<{
    label: string;
    value: string;
  }>(([key, message]) => ({
    value: key,
    label: formatMessage(message),
  }));

  return { formatValue, options };
}
