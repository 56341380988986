import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";
import * as React from "react";

import { RequireSome } from "~/shared/utils";

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "./icon";

const alertVariants = cva("mb-4 grid min-h-14 w-full grid-cols-[auto_1fr_auto] gap-4 rounded px-6 [&+&]:-mt-2", {
  variants: {
    type: {
      info: "bg-blue-30",
      warning: "bg-yellow-30",
      success: "bg-green-30",
      error: "bg-red-30",
    },
  },
});

function InlineAlert({
  children,
  className,
  type,
}: {
  children?: React.ReactNode;
  className?: string;
} & RequireSome<VariantProps<typeof alertVariants>, "type">) {
  const Icon =
    type === "info" ? InfoIcon : type === "warning" ? WarningIcon : type === "success" ? SuccessIcon : ErrorIcon;
  return (
    <div role="alert" className={cn(alertVariants({ type }), className)}>
      <Icon className="my-4 h-6 w-6" />
      {children}
    </div>
  );
}

function InlineAlertContent({ children }: { children?: React.ReactNode }) {
  return <p className="my-4">{children}</p>;
}

function InlineAlertAction({ children }: { children?: React.ReactNode }) {
  return <div className="my-2">{children}</div>;
}

export { InlineAlert, InlineAlertContent, InlineAlertAction };
