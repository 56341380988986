import { FieldValidator } from "@formulier/react";
import isEqual from "lodash.isequal";
import * as React from "react";

import { defineMessages, useIntl } from "~/intl";

export interface Validation {
  required?: boolean | [required: boolean, errorMessage: string];
  minLength?: number | [minLength: number, errorMessage: string];
  maxLength?: number | [maxLength: number, errorMessage: string];
  minValue?: number | Date | [minValue: number | Date, errorMessage: string];
  maxValue?: number | Date | [maxValue: number | Date, errorMessage: string];
  format?: [format: RegExp, errorMessage: string];
  custom?: (value: unknown) => string | undefined;
}

export function useValidateCallback(_validation: Validation | undefined) {
  const { formatMessage } = useIntl();
  const [validation, setValidation] = React.useState(_validation);

  React.useEffect(() => {
    if (!isEqual(_validation, validation)) setValidation(_validation);
  }, [_validation, validation]);

  return React.useCallback<FieldValidator>(
    (value) => {
      if (!validation) return null;

      for (const entry of Object.entries(validation)) {
        const key = entry[0] as keyof Validation;

        if (key === "required") {
          const arg = entry[1] as NonNullable<Validation["required"]>;
          const required = isArray(arg) ? arg[0] : arg;
          const message = isArray(arg) ? arg[1] : undefined;
          if (
            required &&
            (value === null || value === undefined || ((isArray(value) || isString(value)) && value.length === 0))
          ) {
            return message || formatMessage(t.required);
          }
        }

        if (key === "minLength") {
          const arg = entry[1] as NonNullable<Validation["minLength"]>;
          const minLength = isArray(arg) ? arg[0] : arg;
          const message = isArray(arg) ? arg[1] : undefined;
          if (isString(value) || isArray(value)) {
            if (value.length < minLength) {
              return message || formatMessage(t.minLength, { minLength });
            }
          }
        }

        if (key === "maxLength") {
          const arg = entry[1] as NonNullable<Validation["maxLength"]>;
          const maxLength = isArray(arg) ? arg[0] : arg;
          const message = isArray(arg) ? arg[1] : undefined;
          if (isString(value) || isArray(value)) {
            if (value.length > maxLength) {
              return message || formatMessage(t.maxLength, { maxLength });
            }
          }
        }

        if (key === "minValue") {
          const arg = entry[1] as NonNullable<Validation["minValue"]>;
          const minValue = isArray(arg) ? arg[0] : arg;
          const message = isArray(arg) ? arg[1] : undefined;

          let parsedValue: number | Date;

          if (isNumber(value) || isDate(value)) {
            parsedValue = value;

            if (!isNumber(value) && typeof value === "string") {
              parsedValue = Date.parse(value) || new Date();
            }

            if (parsedValue < minValue) {
              return message || formatMessage(t.minValue, { minValue });
            }
          }
        }

        if (key === "maxValue") {
          const arg = entry[1] as NonNullable<Validation["maxValue"]>;
          const maxValue = isArray(arg) ? arg[0] : arg;
          const message = isArray(arg) ? arg[1] : undefined;
          let parsedValue: number | Date;

          if (isNumber(value) || isDate(value)) {
            parsedValue = value;

            if (!isNumber(value) && typeof value === "string") {
              parsedValue = Date.parse(value) || new Date(); // Default to current date if parsing fails
            }

            if (parsedValue > maxValue) {
              return message || formatMessage(t.maxValue, { maxValue });
            }
          }
        }

        if (key === "custom") {
          const arg = entry[1] as NonNullable<Validation["custom"]>;
          const message = arg(value);
          if (message !== undefined) return message;
        }

        if (key === "format") {
          const arg = entry[1] as NonNullable<Validation["format"]>;
          const regex = arg[0];
          const message = arg[1];
          if (isString(value) && value !== "") {
            const isMatch = regex.test(value);
            if (!isMatch) return message !== "" ? message : formatMessage(t.format);
          }
        }
      }

      return null;
    },
    [formatMessage, validation],
  );
}

function isString(value: unknown): value is string {
  return typeof value === "string";
}

function isNumber(value: unknown): value is number {
  return typeof value === "number" && !Number.isNaN(value);
}

function isDate(value: unknown): value is Date {
  return value instanceof Date || (typeof value === "string" && !Number.isNaN(Date.parse(value)));
}

function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}

export const t = defineMessages({
  required: {
    id: "field_error_required",
    defaultMessage: "Value is required",
  },
  minLength: {
    id: "field_error_min_length",
    defaultMessage: "Length must be greater than or equal to {minLength}",
  },
  maxLength: {
    id: "field_error_max_length",
    defaultMessage: "Length must be less than or equal to {maxLength}",
  },
  minValue: {
    id: "field_error_min_value",
    defaultMessage: "Value must be greater than or equal to {minValue}",
  },
  maxValue: {
    id: "field_error_max_value",
    defaultMessage: "Value must be less than or equal to {maxValue}",
  },
  format: {
    id: "field_error_format",
    defaultMessage: "Value should follow vaild format",
  },
});
